//  My Custom Settings for Segway Malaysia app.scss
//  -----------------------------------------------
//official site color
$test-color: #290903; //#2c3840;
//$sm-color: #290903; placed at _setting already 
$topbar-tagline-color: lighten($sm-color, 25%);

// styling for segway-menu.html
@import 'hamburger-mixin';

//segway-menu topbar menu styling ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
.topbar-fixed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    background: $sm-color;
    padding: 10px 10px;


    //adjusting flex direction on small screen 
    @media print,
    screen and (max-width: 40em) {
        flex-direction: column;
        align-items: stretch;
        padding: 0px auto;
    }

    .topbar-responsive-logo {
        font-size: 2rem;

        @media print,
        screen and (max-width: 40em) {
            font-size: 1rem;
        }

    }

    a {
        color: scale-color($sm-color, $lightness: 90%);
    }

    .title-bar {
        justify-content: space-between;
        flex-direction: row-reverse;
        background: $sm-color;

        p.nospan {
            font-size: .8rem;
        }

    }

    p.top-cta {
        color: $topbar-tagline-color;
        display: inline-block;
        margin-bottom: 8px;
        margin-right: 8px;
        margin-left: 18px;
        letter-spacing: .25rem;

    }

}


//the default styling for hamburger mixin
#my-menu-icon,
.my-menu-icon {
    @include hamburger($color: white, $anim: cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.33s);

}

//the solid background styling for hamburger mixin
// use it by referring to the given name ID and label tag used in the segway-menu.html code
#toggle-solid-background {
    @include hamburger($background: lighten($sm-color, 8%), $width: 1rem, $thickness: 3px, $gap: 4px, $padding: 12px, $color: white, $radius_background: 50%);
}


#my-menu {
    background: $sm-color;
    margin-top: 7px;
    width: 50wv;

    .first-sub {
        //background: scale-color($sm-color, $lightness: 80%);
        //background: fade-out($sm-color, .8);
        background: lighten($sm-color, 85%);
        border-radius: 8px;

        a {
            color: scale-color($sm-color, $lightness: -90%);
            padding-left: 30px;

            &:hover {
                background: scale-color(grey, $lightness: 90%);
                border-radius: 8px;
            }

        }

    }

    //in small screen the container bg should be 
    @media print,
    screen and (max-width: 40em) {
        //background: scale-color($sm-color, $lightness: 80%);
        background: lighten($sm-color, 85%);
        width: 100%;

        a {
            color: scale-color($sm-color, $lightness: -90%);

            &:hover {
                background: scale-color(grey, $lightness: 90%);
            }

        }

        ul.submenu > li {
            padding-left: 15px;
        }

    }

}



//styling for faq-accordion
li.accordion-item {
    a.accordion-title {
        color: $sm-color;
        font-size: .95rem;
        font-weight: 600;
    }

    div.accordion-content > p {
        color: $sm-color;
        font-size: .9rem;
        font-weight: 400;
        line-height: 115%;
    }


}

//styling for card in sw-manuals-content paartials
.card {
    .card-divider {
        h4 {
            color: $sm-color;
            font-size: 1.2rem;
            font-weight: 600;
        }

    }

    .card-section {
        ul {
            list-style-type: none;
        }
    }
}

//styling for Contact us h5

.about-box {
    h4 {
        font-weight: 600;
    }

    h5 {
        margin-bottom: 30px;
    }

    .cite-block,
    cite {
        color: fade-out($sm-color, .4);
    }
}

//for the sandbox transition animation 
main {
    opacity: 0;

    @media print,
    screen and (max-width: 40em) {
        margin-bottom: 20px;
    }


    .form-box5 {
        padding-top: 200px;
        text-align: center;
        height: 70vh;
    }

}

//code for morphing-svg
#morph {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    z-index: 80;
}

//code to slide hero h1
#h1 {
    @include mui-animation(slide($direction: left, $amount: 40%), fade);
    animation-duration: 4s;
    animation-delay: 1s;
}

.move {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: $sm-color;
    @include mui-animation(slide($direction: left, $amount: 30%), fade);
    animation-duration: 5s;
    animation-delay: 1s;
}





//segway-footer styling ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
#my-footer {
    background: lighten($sm-color, 5%);
}
