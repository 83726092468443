// styling control for ...-feature-box.html

//styling dedicated for patroller feature-box
.dark-bg-box {
    background: $sm-color;
    padding: 40px;
    -webkit-box-shadow: 0px 5px 15px 5px #000000;
    box-shadow: 0px 5px 15px 5px #000000;

    .marketing-site-features {

        h3 {
            margin-bottom: 60px;
        }

        h3,
        .flexy-box i,
        .flexy-box h4 {
            color: white;
        }

        .flexy-box {
            h4 {
                margin-top: 20px;
                font-weight: 400;

                @media screen and (max-width: 40em) {
                    margin-bottom: 40px;

                }
            }

            .button {
                background: lighten($sm-color, 10%);
            }


        }

    }
}

.marketing-site-features {
    text-align: center;
    padding: 2rem 0;

    .flexy-box {
        @include flex;
        @include flex-align(center, middle);
        @include flex-direction(column);
        @include flex-align-self(top);

        i {
            font-size: 3rem;
            margin-bottom: .5rem;
            color: $sm-color;
        }

        .marketing-site-features-title {
            text-align: center;
            color: lighten($sm-color, 30%);
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: .5rem;
        }

        .marketing-site-features-desc {
            text-align: center;
            //color: lighten($sm-color, 30%);
            font-size: 1rem;
            margin-bottom: .5rem;
        }

        .button {
            margin-top: 20px;
            font-weight: 700;
            color: white;
        }
    }



    @media screen and (max-width: 40em) {
        text-align: left;
    }
}

.marketing-site-features-headline {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: $sm-color;
}

.marketing-site-features-subheadline {
    margin-bottom: 2rem;
    text-align: center;

}
