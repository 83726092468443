//...-marketing-box styling control

$image-hover-wrapper-transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
$image-hover-wrapper-transition-delay: 0.25s;
$image-hover-wrapper-banner-width: 100%;
$image-hover-wrapper-banner-topspace: 50%;
$image-hover-wrapper-banner-bg: rgba($primary-color, 0.8);
$image-hover-wrapper-icon-size: 24px;

//creating in between space for the container
.all-pad {
    padding: 40px;
    //background: lighten($sm-color, 10%);
    background: fade-out($sm-color, .5);

    .orbit {

        .orbit-container {
            border-radius: $global-radius;

            li,
            li.orbit-slide,
            li > .orbit-slide.is-active {
                border-radius: $global-radius;
            }
        }
    }

    @media print,
    screen and (max-width: 40em) {
        padding: 10px;

        .orbit {

            .orbit-container {
                border-radius: $global-radius;

                li,
                li.orbit-slide,
                li > .orbit-slide.is-active {
                    border-radius: $global-radius;
                }
            }
        }

        .image-hover-wrapper {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

//styling for front marketing-box
.image-hover-wrapper {
    position: relative;

    a {
        display: inline-block;
        letter-spacing: normal;
        line-height: initial;
        margin: 0;
        padding: 0;
        position: relative;

        img {
            float: left;
            overflow: hidden;
            width: 100%;
        }

        .image-hover-wrapper-reveal {
            background-color: rgba(0, 0, 0, 0.5);
            color: $white;
            display: inline;
            height: 100%;
            margin-left: -100%;
            opacity: 0;
            position: absolute;
            width: 100%;
            z-index: 80;
            transition: $image-hover-wrapper-transition;
            transition-delay: $image-hover-wrapper-transition-delay;

            &:hover {
                opacity: 1;
                transition: $image-hover-wrapper-transition;
            }

            &:hover p,
            &:hover button {
                opacity: 1;
                bottom: 30%;
                right: 35%;
                //transform: translate(160%, 900%);
                transition: $image-hover-wrapper-transition;
                transition-delay: $image-hover-wrapper-transition-delay;

                @media print,
                screen and (max-width: 40em) {
                    bottom: 10%;
                    right: 30%;

                }
            }

            p,
            button {
                color: white;
                text-transform: uppercase;
                bottom: 15%;
                right: 35%;
                //transform: translate(160%, 1100%);
                display: block;
                opacity: 0;
                position: absolute;
                //left: 40%;
                //margin: 0 auto;
                //text-align: center;
                //width: 50%;
                transition: $image-hover-wrapper-transition;

                @media print,
                screen and (max-width: 40em) {
                    transform: scale(0.8);
                    bottom: 0%;
                    right: 30%;
                }
            }
        }

        .icofont-link-alt {
            font-size: $image-hover-wrapper-icon-size;
        }
    }

    .image-hover-wrapper-banner {
        //background-color: $image-hover-wrapper-banner-bg;
        background-color: fade-out($sm-color, .5);
        text-transform: uppercase;
        color: $white;
        font-weight: 600;
        left: 0;
        padding: 0.5rem;
        position: absolute;
        top: $image-hover-wrapper-banner-topspace;
        width: $image-hover-wrapper-banner-width;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        -moz-border-radius: 0px 20px 20px 0px;
        -webkit-border-radius: 0px 20px 20px 0px;
        border: 0px none #000000;

        @media print,
        screen and (max-width: 40em) {
            //transform: scale(0.8);
        }
    }
}

//styling for slider marketing-box at product functionalities section
.func-header {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: $sm-color;

    @media print,
    screen and (max-width: 40em) {
        margin-top: 30px;
    }

}

.func-hover-wrapper {
    position: relative;
    border-radius: $global-radius;


    a {
        display: inline-block;
        letter-spacing: normal;
        line-height: initial;
        margin: 0;
        padding: 0;
        position: relative;
        border-radius: $global-radius;


        img {
            float: left;
            overflow: hidden;
            width: 100%;
            border-radius: $global-radius;
        }

        .func-hover-wrapper-reveal {
            background-color: rgba(0, 0, 0, 0.5);
            color: $white;
            display: inline;
            height: 100%;
            margin-left: -100%;
            opacity: 0;
            position: absolute;
            width: 100%;
            z-index: 80;
            transition: $image-hover-wrapper-transition;
            transition-delay: $image-hover-wrapper-transition-delay;
            border-radius: $global-radius;

            &:hover {
                opacity: 1;
                transition: $image-hover-wrapper-transition;
                border-radius: $global-radius;
            }

            &:hover p {
                opacity: 1;
                bottom: 5%;
                transition: $image-hover-wrapper-transition;
                transition-delay: $image-hover-wrapper-transition-delay;

                @media print,
                screen and (max-width: 40em) {
                    bottom: 5%;
                    right: 0%;
                    font-size: .85rem;

                }
            }

            &:hover button {
                opacity: 1;
                bottom: 5%;
                right: 0%;
                //transform: translate(160%, 900%);
                transition: $image-hover-wrapper-transition;
                transition-delay: $image-hover-wrapper-transition-delay;

                @media print,
                screen and (max-width: 40em) {
                    bottom: 10%;
                    right: 30%;

                }
            }

            p {
                text-align: center;
                width: 100%;
                bottom: 0%;
                right: 0%;
                display: block;
                opacity: 0;
                position: absolute;
                padding: 0 20px;
            }


            button {
                color: white;
                text-transform: uppercase;
                bottom: 0%;
                right: 35%;
                //transform: translate(160%, 1100%);
                display: block;
                opacity: 0;
                position: absolute;
                //left: 40%;
                //margin: 0 auto;
                //text-align: center;
                //width: 50%;
                transition: $image-hover-wrapper-transition;

                @media print,
                screen and (max-width: 40em) {
                    transform: scale(0.8);
                    bottom: 0%;
                    right: 30%;
                }
            }
        }

        .icofont-link-alt {
            font-size: $image-hover-wrapper-icon-size;
        }
    }

    .image-hover-wrapper-banner {
        //background-color: $image-hover-wrapper-banner-bg;
        background-color: fade-out($sm-color, .5);
        text-transform: uppercase;
        color: $white;
        font-weight: 600;
        left: 0;
        padding: 0.5rem;
        position: absolute;
        top: $image-hover-wrapper-banner-topspace;
        width: $image-hover-wrapper-banner-width;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        -moz-border-radius: 0px 20px 20px 0px;
        -webkit-border-radius: 0px 20px 20px 0px;
        border: 0px none #000000;

        @media print,
        screen and (max-width: 40em) {
            transform-origin: bottom left;
            transform: scale(0.8) translate(0%, -200%);
        }
    }
}


//styling for content pages
.all-pad-content-white {
    padding: 40px;
    background: fade-out($sm-color, 1.0);

    @media print,
    screen and (max-width: 40em) {
        padding: 10px;
    }

}

.all-pad-content {
    padding: 40px;
    //background: lighten($sm-color, 10%);
    background: fade-out($sm-color, .7);

    @media print,
    screen and (max-width: 40em) {
        padding: 10px;
    }

    .box-content {

        h3 {
            color: $sm-color;
            padding: 0 5%;
            text-transform: capitalize;
        }

        p {
            color: $sm-color;
            line-height: 140%;
            font-size: 1rem;
            padding: 0 5%;
        }
    }


}

#sound-fx {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: auto;

        li {
            flex-basis: 50%;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 10px;
            //color: $mobile-app-dashboard-item-color;
            display: flex;
            flex-direction: column;
            font-size: .8em;
            color: white;
            border-bottom: 1px solid white;
        }
    }

    .flexy {
        display: flex;
        flex-wrap: nowrap;

        @media print,
        screen and (max-width: 40em) {
            flex-direction: column;
        }

    }
}
