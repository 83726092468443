// styling control for ...content-compare-box.html & ...spec-box.html & ...corp-solutions.html

.compare-box {
    .compare-headline {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: $sm-color;
    }

    table {
        border-collapse: collapse;
        overflow: hidden;
        border-radius: $global-radius;
    }

    table,
    th,
    tr,
    td {
        color: $sm-color;
    }

    td {
        text-align: center;
    }

    td:first-child {
        text-align: left;
        vertical-align: text-top;
    }

    .button {
        background: $sm-color;
        color: white;
        font-weight: 600;
        margin-top: 20px;
    }

    blockquote {
        font-size: .9rem;
        color: white;
        margin-left: 50px;
        border: none;

        @media screen and (max-width: 40em) {
            font-size: .8rem;
            margin-left: 20px;
        }

    }

    blockquote:first-of-type {
        margin-top: 20px;
    }
}


.spec-box {
    h4 {
        color: $sm-color;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 20px;
    }

    table {
        margin: 0 auto;
        width: 85%;

        @media screen and (max-width: 40em) {

            margin: 0 auto;
            width: 90vw;
            font-size: .8rem;

        }

    }

    .th-tb {
        width: 30%;
        text-align: right;
        vertical-align: top;
        text-transform: capitalize;
    }

    td {
        text-align: left;
        vertical-align: top;
    }

}


// styling control for corp-solutions page

.featured-image-block-grid-header {
    margin-left: auto;
    margin-right: auto;

    h2 {
        text-transform: uppercase;
        font-weight: 700;
        color: $sm-color;
    }

}

.featured-image-block {
    margin-bottom: 20px;

    img {
        padding: 0px 8px;

        @media screen and (max-width: 40em) {
            padding: 0px 3px;
        }
    }

    .featured-image-block-title {
        font-weight: 600;
        margin-top: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1em;
        color: #404040;

        @media screen and (max-width: 40em) {
            font-size: .8em;
            letter-spacing: .015em;
        }
    }

    a:hover {
        p {
            color: map-get($foundation-palette, primary);
        }
    }
}

.solution-desc {
    h4 {
        text-align: center;
        color: $sm-color;
        font-weight: 600;
    }

    p {
        color: $sm-color;
    }

}
