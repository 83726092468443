//styling for segway-main-hero.html ++++++++++++++++++++++++++++++++

$hero-height: 100vh;
$half-height: 50vh;

.hero-section {
    background: url('../img/main00-segway-ks.jpg') 50% no-repeat;
    background-size: cover;
    height: $hero-height;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01i-segway-ks.jpg') 50% no-repeat;
        background-size: cover;

    }

    .hero-section-text {
        color: $white;
        text-shadow: 1px 1px 2px $black;

        @media print,
        screen and (max-width: 40em) {
            h3 {
                font-size: .95rem;
                padding: 0 50px;
            }

            p {
                font-size: .95rem;
                padding: 0 20px;
            }
        }
    }
}


//styling for hero on the other pages ++++++++++++++++++++++++++++++++
.pg-hero-section {
    background-size: cover;
    background-position: center center;
    height: $hero-height;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media print,
    screen and (max-width: 40em) {
        //background: url('../img/vert01i-segway-ks.jpg') 50% no-repeat;
        background-size: cover;

    }

    .pg-hero-section-text {
        color: $white;
        text-shadow: 1px 1px 2px $black;

        p {
            font-size: 1.25rem;
            padding: 0 10%;
        }

        @media print,
        screen and (max-width: 40em) {

            h2 {
                font-weight: 600;
            }

            h3 {
                font-size: 1rem;
                padding: 0 50px;
                font-weight: 600;
            }

            p {
                font-size: .95rem;
                line-height: 130%;
                padding: 0 20px;
            }
        }
    }


}

.rec-ks2 {
    background: url('../img/rec-hero-01-segway-ks.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/rec-vert02-segway-ks.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-ksmax {
    background: url('../img/rec-hero-01-segway-ksmax2.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-ksmax.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-drift-w1 {
    background: url('../img/rec-hero-01-segway-drift-w1.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-drift-w1.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-gokart-kit {
    background: url('../img/main01-gokart-kit-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-gokart-kit-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-gokart-pro {
    background: url('../img/main01-gokart-pro-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-gokart-pro-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-gokart-lamborg {
    background: url('../img/main01-gokart-lamborg-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-gokart-lamborg-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-s-pro {
    background: url('../img/main01-s-pro-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-s-pro-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-s-plus {
    background: url('../img/main01-s-plus-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-s-plus-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-one {
    background: url('../img/main01-one-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-one-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-one-s2 {
    background: url('../img/main01-one-s2-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-one-s2-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.rec-one-z10 {
    background: url('../img/main01-one-z10-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-one-z10-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }
}

.pro-loomo-pr {
    background: url('../img/main01-loomo-pr-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-loomo-pr.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-x2-se {
    background: url('../img/main01-segway-x2-se-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-x2-se-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-i2-se {
    background: url('../img/main01-segway-i2-se-hero.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-i2-se-hero.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-elite-p {
    background: url('../img/main01-sw-elite-p.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-sw-elite-p.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-x2-patroller {
    background: url('../img/main01-segway-x2-se-patroller.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-x2-se-patroller.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-i2-patroller {
    background: url('../img/main01-segway-i2-se-patroller.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-i2-se-patroller.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-se-3 {
    background: url('../img/main01-segway-se-3.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-se-3.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-windrunner-gx1 {
    background: url('../img/main01-windrunner-gx1.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-windrunner-gx1.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h3 {
        padding: 0 100px;
    }
}

.pro-corp-sol {
    background: url('../img/main01-corp-solutions-header.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $half-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-corp-solution-header.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $half-height;
    }

    h1 {

        @media print,
        screen and (max-width: 40em) {
            margin-top: 60px;
            padding: 0 50px;
        }

    }

    h3 {
        padding: 0 100px;
    }
}

.support-pgs {
    background: url('../img/main01-half-header-support.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $half-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-half-header-support.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $half-height;
    }

    h1 {

        @media print,
        screen and (max-width: 40em) {
            margin-top: 60px;
            padding: 0 50px;
        }

    }

    h3 {
        padding: 0 100px;
    }
}

.form-pg {
    background: url('../img/main01-segway-form-bg.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: $hero-height;

    @media print,
    screen and (max-width: 40em) {
        background: url('../img/vert01-segway-form-bg.jpg') no-repeat;
        background-position: center center;
        background-size: cover;
        height: $hero-height;
    }

    h1 {

        @media print,
        screen and (max-width: 40em) {
            margin-top: 60px;
            padding: 0 50px;
        }

    }

    h3 {
        padding: 0 100px;
    }
}
