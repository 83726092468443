$marketing-site-footer-bg: $sm-color;

.marketing-site-footer {
    background: $marketing-site-footer-bg;
    color: lighten($marketing-site-footer-bg, 40%);
    padding: 2rem 0 0;

    .marketing-site-footer-menu-social a {
        color: $white;
    }

    .column-block {
        margin-bottom: 30px;
    }

    > .grid-x {
        margin-bottom: 1rem;
    }

    p {
        //color: fade-out($sm-color, .5);
        //color: desaturate($sm-color, 10%);
        color: lighten($sm-color, 60%);
    }

    @include breakpoint(small only) {
        .cell {
            margin-bottom: 1rem;
            padding: 1rem;

            p {
                font-size: .85rem;
            }
        }
    }
}

.marketing-site-footer-name {
    color: $white;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 700;

    @include breakpoint(small only) {
        font-size: 1.15rem;

    }
}

.marketing-site-footer-title {
    color: $white;
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.marketing-site-footer-block {
    display: flex;
    margin-bottom: 1rem;

    .fa {
        font-size: 2rem;
        color: darken($marketing-site-footer-bg, 0%);
    }

    p {
        margin-left: 1rem;
        line-height: 1.125rem;
    }
}

.marketing-site-footer-bottom {
    background: darken($marketing-site-footer-bg, 2%);
    padding: 1rem 0;

    p,
    .menu {
        margin-bottom: 0;
    }

    .marketing-site-footer-bottom-links {
        justify-content: flex-end;

        a {
            color: lighten($marketing-site-footer-bg, 40%);
        }

        @include breakpoint(medium down) {
            justify-content: center;
        }
    }

    @include breakpoint(medium down) {
        text-align: center;
    }
}
