//styling for segway-front-prod-card.html

.prod-card-section-header {
    text-align: center;
    margin-bottom: 20px;
    color: $sm-color;
    font-weight: 800;
    line-height: 120%;
}

$card-product-hover-transition: all 0.2s ease;

.card-product {

    &:hover {
        .card-product-img-wrapper {

            img {
                filter: grayscale(0);
            }

            .button {
                transform: translateY(-3rem);
            }
        }

        .card-section {
            a {
                color: fade-out($sm-color, 0);
            }
        }
    }
}

.card-product-img-wrapper {
    margin-bottom: 1.375rem;
    position: relative;
    overflow: hidden;

    .button {
        transition: $card-product-hover-transition;
        background-color: $sm-color; //$secondary-color;
        padding: 1rem 0.5rem;
        bottom: -3rem;
        transform: translateY(3rem);
        position: absolute;
        z-index: 2;
        color: $white;
        font-weight: 900;
        margin-bottom: 0;

        &:hover {
            background-color: scale-color($button-background, $lightness: -15%); //$primary-color;
        }
    }

    img {
        transition: $card-product-hover-transition;
        filter: grayscale(1);
        width: 100%;
    }

    .card-product-price {
        font-weight: bold;
    }

    .card-product-description {
        color: $dark-gray;
        font-size: 0.875rem;
        margin-bottom: 0;
    }
}

.card-section {
    a {
        color: fade-out($sm-color, .5);
        font-weight: 700;

        .card-product-name {
            font-weight: 700;
            font-size: 1.5rem;
        }

    }
}

.front-prod-card {
    .button {
        color: white;
        font-weight: 600;

    }
}

.flexy-box {
    @include flex;
    @include flex-align(center, middle);
    @include flex-direction(column);
    @include flex-align-self(top);
}
